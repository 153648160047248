export default function Avatar({ avatar, department, name, title, body }) {
  return (
    <>
      <div className="flex items-start md:items-center justify-center bg-gray-100 rounded-lg p-4">
        <img src={avatar} alt={name} className={`w-[80px] md:w-[146px]`} />
        <div className={`ml-4 mr-4 leading-0 lg:leading-10`}>
          <p className={`text-primary`}>
            <span className={`text-sm md:text-xl`}>{department}</span>{" "}
            <span className={`text-xl md:text-2xl`}>{name}</span> {title}
          </p>
          <p className={`mt-2 text-xs md:text-lg`}>{body}</p>
        </div>
      </div>
    </>
  );
}
