const Footer = () => {
  return (
    <>
      <div className={`bg-footer p-4 pb-28`}>
        <div className={`text-white leading-10 max-w-7xl m-auto relative`}>
          期權及槓桿保證金契約各類型交易，皆具高財務槓桿特性，交易人可能承受極大的損失亦或有極大獲利，且需承擔交易上及其他損失之風險（該風險可能極為重大）；又使用電子式交易，仍可能面臨斷線、斷電、網路壅塞等不確定因素，致使買賣指令無法即時傳送或延遲。以上風險甚為簡要，對所有投資風險及影響市場行情之因素無法逐一詳述，交易人應依自身之財務狀況、經驗、目標及其他相關情況，審慎評估此類交易是否合宜，交易人也應確認完全瞭解個別契約、交易及合約權利義務特性及所暴露損失風險的特性及範圍後為之。且本公司將不負責工具或任何交易所產生的任何損失。元大期貨
          許可證照字號：110年金管期總字第 001 號 | 客戶服務專線：(02)2326-1000 /
          0800-333-338 | 電話：(02)2717-6000 |
          地址：臺北市中山區南京東路二段77號2樓(部分)、3、4、5樓。
          <div className={`absolute right-0 -bottom-20`}>
            <ul className={`flex items-center justify-around`}>
              <li className={`mr-4`}>
                <a href={`${process.env.REACT_APP_FACEBOOK}`} target={`_blank`}>
                  <img src="/img/footer_fb.png" alt="fb" />
                </a>
              </li>
              <li className={`mr-4`}>
                <a href={`${process.env.REACT_APP_YOUTUBE}`} target={`_blank`}>
                  <img src="/img/footer_yb.png" alt="yb" />
                </a>
              </li>
              <li>
                <a href={`${process.env.REACT_APP_LINE}`} target={`_blank`}>
                  <img src="/img/footer_line.png" alt="line" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
