import React from "react";
import ReactDOM from "react-dom/client";
import "./global.css";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import Home from "./pages/Home";
import Layout from "./Layout";
import Futures from "./pages/Futures";
import Lever from "./pages/Lever";
import Register from "./pages/Register";
import Winner from "./pages/Winner";

const router = createBrowserRouter([
  // {
  //   path: "/",
  //   Component: Futures,
  // },
  { path: "*", Component: Root },
]);

function Root() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path={`/`} element={<Register />} />
        {/*<Route path={`/futures`} element={<Futures />} />*/}
        {/*<Route path={`/lever`} element={<Lever />} />*/}
        {/*<Route path={`/register`} element={<Register />} />*/}
        {/*<Route path={`/winner`} element={<Winner />} />*/}
      </Route>
    </Routes>
  );
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
