import { Outlet } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { HelmetProvider } from "react-helmet-async";

const Layout = () => {
  return (
    <>
      <HelmetProvider>
        <Header />
        <main>
          <Outlet />
          <a
            onClick={(e) => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
            className={`fixed right-4 bottom-10 z-30 w-14 cursor-pointer`}
          >
            <img src="/img/gtop.png" alt="top" />
          </a>
        </main>
        <Footer />
      </HelmetProvider>
    </>
  );
};
export default Layout;
