import { Link } from "react-router-dom";
import { useState } from "react";

function MobileNav() {
  const [hamburger, setHamburger] = useState(true);
  return (
    <>
      <div
        className={`fixed z-50 w-full bg-white block flex items-center justify-between pt-2 pb-1 pl-4 pr-4`}
      >
        <div>
          <a href="/">
            <img src={`/img/logo.png`} className={`w-28`} alt="logo" />
          </a>
        </div>
        <div className="">
          <div
            className={
              hamburger
                ? "hamburger hamburger--spring js-hamburger"
                : "hamburger hamburger--spring js-hamburger is-active"
            }
            onClick={() => setHamburger(!hamburger)}
          >
            <div className="hamburger-box">
              <div className="hamburger-inner"></div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          !hamburger
            ? `fixed flex flex-col min-h-screen pt-[40px] z-40 w-full text-white block md:block lg:hidden`
            : `hidden`
        }
        style={{
          height: `${!hamburger ? "min-h-screen" : ""}`,
          overflow: `${!hamburger ? "scroll" : ""}`,
        }}
      >
        <div className={`bg-primary`}>
          <div className={`border-b border-white p-4 text-xl`}>
            {/*<Link to="/futures">外期活動</Link>*/}
            <a onClick={(e) => alert(`建置中`)}>外期活動</a>
          </div>
          <div className={`border-b border-white p-4 text-xl`}>
            {/*<Link to="/lever">槓桿活動</Link>*/}
            <a onClick={(e) => alert(`建置中`)}>槓桿活動</a>
          </div>

          <div className={`border-b border-white p-4 text-xl`}>
            <Link to="/">講座論壇</Link>
          </div>

          <div className={`border-b border-white p-4 text-xl`}>
            {/*<Link to="/winner">中獎名單</Link>*/}
            <a onClick={(e) => alert(`建置中`)}>中獎名單</a>
          </div>

          <div className={`p-4 text-xl`}>
            <div className="flex items-center">
              <div className={`mr-6`}>
                <a href="" target={`_blank`}>
                  <img src="/img/footer_fb.png" className={`w-10`} alt="" />
                </a>
              </div>
              <div className={`mr-6`}>
                <a href="" target={`_blank`}>
                  <img src="/img/footer_yb.png" className={`w-10`} alt="" />
                </a>
              </div>
              <div className={`mr-6`}>
                <a href="" target={`_blank`}>
                  <img src="/img/footer_line.png" className={`w-10`} alt="" />
                </a>
              </div>
              <div>
                <a href="" target={`_blank`}>
                  <img src="/img/cs.png" className={`w-10`} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const Header = () => {
  return (
    <>
      <div className={`hidden md:block`}>
        <div className={`py-6 px-4 border-b shadow-gray-100 shadow-lg`}>
          <nav className={`flex justify-between items-center`}>
            <h1 className={``}>
              <Link to={`/`}>
                <img
                  src={`/img/logo.png`}
                  className={`h-[49px]`}
                  alt={`Logo`}
                />
              </Link>
            </h1>
            <ul
              className={`flex justify-center items-center text-primary text-xl font-bold`}
            >
              <li className={`mr-4`}>
                <a
                  onClick={(e) => alert(`建置中`)}
                  // to={`/futures`}
                  className={`group transition duration-300 cursor-pointer`}
                >
                  外期活動
                  <span
                    className={`block max-w-0 group-hover:max-w-full transition-all duration-500 group-hover:h-0.5 bg-primary`}
                  ></span>
                </a>
              </li>
              <li className={`mr-4`}>
                <a
                  onClick={(e) => alert(`建置中`)}
                  // to={`/lever`}
                  className={`group transition duration-300 cursor-pointer`}
                >
                  槓桿活動
                  <span
                    className={`block max-w-0 group-hover:max-w-full transition-all duration-500 group-hover:h-0.5 bg-primary`}
                  ></span>
                </a>
              </li>
              <li className={`mr-4`}>
                <Link
                  to={`/`}
                  className={`group transition duration-300 cursor-pointer`}
                >
                  講座論壇
                  <span
                    className={`block max-w-0 group-hover:max-w-full transition-all duration-500 group-hover:h-0.5 bg-primary`}
                  ></span>
                </Link>
              </li>
              <li className={`mr-8`}>
                <a
                  onClick={(e) => alert(`建置中`)}
                  // to={`/winner`}
                  className={`group transition duration-300 cursor-pointer`}
                >
                  得獎名單
                  <span
                    className={`block max-w-0 group-hover:max-w-full transition-all duration-500 group-hover:h-0.5 bg-primary`}
                  ></span>
                </a>
              </li>
              <li>
                <div className={`grid gap-3 grid-cols-3`}>
                  <div>
                    <a
                      href={`${process.env.REACT_APP_YOUTUBE}`}
                      target={`_blank`}
                    >
                      <img src={`/img/yb.png`} />
                    </a>
                  </div>
                  <div>
                    <a
                      href={`${process.env.REACT_APP_FACEBOOK}`}
                      target={`_blank`}
                    >
                      <img src={`/img/fb.png`} />
                    </a>
                  </div>
                  <div>
                    <a href={`${process.env.REACT_APP_LINE}`} target={`_blank`}>
                      <img src={`/img/line.png`} />
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div className={`block md:hidden`}>
        <MobileNav />
      </div>
    </>
  );
};
export default Header;
